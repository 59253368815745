import { redirectToAppByUser, saveAuthToken } from '@rtt-libs/auth';
import loginApi from '../../api/signin';
import { SubmissionResult, ThunkResult } from '../../types';
import formErrorTransform from '../../utils/formErrorTransform';
import { Actions, signInFailed, signInSuccess } from './actions';

/**
 * Authorize user, save token in cookies & redirect to app.
 * @param values - Form values
 */
export const loginThunk = (
  values,
): ThunkResult<SubmissionResult | Promise<SubmissionResult>, Actions> => async (
  dispatch,
): Promise<SubmissionResult> => {
  try {
    const { data } = await loginApi(values);

    dispatch(signInSuccess(data));

    saveAuthToken(data.accessToken);

    redirectToAppByUser(data.user, undefined, () =>
      // TODO: replace alert with notification
      // eslint-disable-next-line no-alert
      alert('У вас відсутні права для входу до системи'),
    );

    return undefined;
  } catch (e) {
    const actionDispatcher = (message: string): void => {
      dispatch(signInFailed(message));
    };
    return formErrorTransform(e, actionDispatcher);
  }
};
