import { ActionCreator } from 'redux';
import { ActionType } from '../../types';
import * as TYPES from './types';

export const signInSuccess: ActionCreator<ActionType> = payload => ({
  type: TYPES.SIGNIN_SUC,
  payload,
});

export const signInFailed: ActionCreator<ActionType> = (reason: string) => ({
  type: TYPES.SIGNIN_FAIL,
  payload: reason,
});

export type Actions =
  | ReturnType<typeof signInSuccess>
  | ReturnType<typeof signInFailed>;
