import { createStore, applyMiddleware, StoreEnhancer, Store } from 'redux';
import thunkMiddleware from 'redux-thunk';

import rootReducer from './rootReducer';

const bindMiddleware = (middleware): StoreEnhancer => {
  if (process.env.NODE_ENV !== 'production') {
    if (process.env.REDUX_REMOTE_DEVTOOLS) {
      // eslint-disable-next-line import/no-extraneous-dependencies, @typescript-eslint/no-var-requires
      const { composeWithDevTools } = require('remote-redux-devtools');
      const composeEnhancers = composeWithDevTools();
      return composeEnhancers(applyMiddleware(...middleware));
    }
    // eslint-disable-next-line import/no-extraneous-dependencies, @typescript-eslint/no-var-requires
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const initStore = (initialState = {}): Store => {
  const store = createStore(
    rootReducer,
    initialState,
    bindMiddleware([thunkMiddleware]),
  );

  return store;
};

export default initStore;
