import { FEATURE_SMS_SERVICE } from '../environment';

export const runOnFeatureFlagEnabled = (
  featureFlagValue: boolean,
  fn: () => void,
) => {
  if (featureFlagValue) {
    fn();
  }
};

/**
 * Display code in alert on disabled FEATURE_SMS_SERVICE flag
 *
 * @param code displayed code in alert
 */
export const conditionallyDisplayConfirmCode = (code?: string | number) => {
  runOnFeatureFlagEnabled(!FEATURE_SMS_SERVICE, () => {
    // eslint-disable-next-line no-alert
    alert(`Свіжий код із SMS: ${code}`);
  });
};
