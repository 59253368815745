import { AxiosResponse } from 'axios';
import { ENDPOINTS } from '../environment';
import api from './setup';
import { AuthResponseType } from '../types';

const loginApi = ({
  login,
  password,
}): Promise<AxiosResponse<AuthResponseType>> =>
  api.post(ENDPOINTS.signin, {
    login,
    password,
  });

export default loginApi;
