import { SubmissionErrors } from 'final-form';
import React from 'react';
import { useDispatch } from 'react-redux';
import { loginThunk } from '../duck/thunks';
import LoginForm from './LoginForm';

const LoginContainer: React.FC = () => {
  const dispatch = useDispatch();

  const onLoginSubmit = async (values): Promise<void | SubmissionErrors> => {
    return dispatch(loginThunk(values));
  };

  return <LoginForm onSubmit={onLoginSubmit} />;
};

export default LoginContainer;
