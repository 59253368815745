import {
  Button,
  createStyles,
  FormHelperText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { Field, Form } from 'react-final-form';
import TextFieldAdapter from '../../common/TextFieldAdapter';
import { OnSubmitFinalFormType } from '../../types';
import { composeValidators, minLength, required } from '../../utils/validators';

export type FormValues = {
  login: string;
  password: string;
};

type Props = {
  onSubmit: OnSubmitFinalFormType<FormValues>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonMargin: {
      marginTop: theme.spacing(4),
    },
  }),
);

const loginFieldHelperText =
  "Введіть телефонний номер у форматі +38... або ім'я користувача, яке надав ваш адміністратор";

const RegistrationForm: React.FC<Props> = ({ onSubmit }) => {
  const styles = useStyles({});
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, submitError, submitting }): React.ReactElement => (
        <form onSubmit={handleSubmit}>
          <Field
            name="login"
            type="text"
            component={TextFieldAdapter}
            label="Логін"
            fullWidth
            validate={required}
            helperText={loginFieldHelperText}
            margin="normal"
            autoComplete="tel"
            required
          />
          <Field
            name="password"
            type="password"
            component={TextFieldAdapter}
            label="Пароль"
            fullWidth
            validate={composeValidators(required, minLength(6))}
            margin="normal"
            required
          />
          <FormHelperText error={!!submitError}>{submitError}</FormHelperText>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            className={styles.buttonMargin}
            disabled={submitting}
          >
            Увійти до системи
          </Button>
        </form>
      )}
    </Form>
  );
};

export default RegistrationForm;
