import { combineReducers } from 'redux';
import registration from '../registration/duck';
import auth from '../auth/duck';

const rootReducer = combineReducers({
  registration,
  auth,
});

export type State = ReturnType<typeof rootReducer>;

export default rootReducer;
