import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const myriadProBold = {
  fontFamily: 'MyriadPro',
  fontStyle: 'normal',
  fontDisplay: 'swap' as const,
  fontWeight: 700,
  src: `
  url('/MyriadPro-Bold.woff2') format('woff2'),
  url('/8141.otf') format('opentype'),
    local('MyriadPro'),
    local('Myriad Pro')
  `,
};
const myriadProRegular = {
  fontFamily: 'MyriadPro',
  fontStyle: 'normal',
  fontDisplay: 'swap' as const,
  fontWeight: 400,
  src: `
  url('/MyriadPro-Regular.woff2') format('woff2'),
    local('MyriadPro'),
    local('Myriad Pro')
  `,
};

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#FFFFFF',
    },
    primary: {
      main: '#457DFC',
    },
    secondary: {
      main: '#18AF51',
    },
    error: {
      main: '#F7664F',
    },
    text: {
      primary: '#1B0930',
      secondary: '#90A0B7',
      hint: '#C2CFE0',
      disabled: '#D5D5D5',
    },
    divider: '#EBEFF2',
    // contrastThreshold: 1.5,
  },
  typography: {
    fontFamily: 'MyriadPro, Montserrat, Helvetica, Arial, sans-serif',
    fontSize: 18,
    button: {
      fontWeight: 600,
      textTransform: 'none',
    },
    h3: {
      fontSize: 32,
      fontWeight: 700,
      fontFamily: 'Montserrat',
      textTransform: 'uppercase',
    },
    h4: {
      fontSize: 36,
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
    },
    body1: {
      fontSize: 18,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [myriadProRegular, myriadProBold],
      },
    },
    MuiButton: {
      root: {
        padding: '12px 24px',
        borderRadius: '23px',
      },
      containedPrimary: {
        background: 'linear-gradient(to right, #FF23F2, #457DFC)',
      },
      text: {
        padding: '10px 24px',
      },
      outlined: {
        padding: '10px 24px',
      },
      label: {
        textAlign: 'center',
      },
      sizeLarge: {
        padding: '12px 36px',
        borderRadius: '28px',
      },
    },
    MuiListItemIcon: {
      root: {
        color: '#C2CFE0',
      },
    },
    MuiBadge: {
      colorPrimary: {
        backgroundColor: '#334D6E',
        borderRadius: 4,
        fontSize: 9,
      },
    },
    MuiTableCell: {
      root: {
        borderBottomColor: '#EBEFF2',
      },
      head: {
        opacity: 0.5,
        fontWeight: 600,
      },
    },
    MuiTableRow: {
      root: {
        '&.MuiTableRow-hover:hover': {
          backgroundColor: 'rgba(16, 156, 241, 0.05)', // #F2F9FF ~~ thru primary with opacity
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '23px',
      },
      outlined: {
        border: '4px solid #457DFC',
      },
    },
  },
  props: {
    MuiCheckbox: {
      color: 'primary',
      size: 'small',
    },
    MuiSvgIcon: {
      fontSize: 'small',
    },
  },
});

export default responsiveFontSizes(theme);
