import { Reducer } from 'redux';
import { types as SIGNUP_TYPES } from '../../registration';
import { types as SIGNIN_TYPES } from '../../login';

const initialState = {
  accessToken: undefined as string,
  user: null,
};

export type State = typeof initialState;

const reducer: Reducer<State> = (state = initialState, { type, payload }) => {
  switch (type) {
    case SIGNIN_TYPES.SIGNIN_SUC:
    case SIGNUP_TYPES.SIGNUP_SUC:
      return {
        ...state,
        ...payload,
      };
    case SIGNIN_TYPES.SIGNIN_FAIL:
    case SIGNUP_TYPES.SIGNUP_FAIL:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
